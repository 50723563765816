import moment from 'moment';

import { COMPANY_COLOURS, COMPANY_COLOURS_DARK } from '@/shared/constants';
import { compare, formatWordsWithSeparator } from '@/shared/tools';

import type { IORCompany } from '@/features/company/shared/models';
import { SVGIcon } from '@/components/structural/images';
import warningIcon from '@/icons/exclamation-triangle-fill.svg';
import { DataFormat } from '@/components/structural';


export const deadlinePriorityColours = {
    OVERDUE: '!bg-white dark:!bg-dark !text-danger dark:!text-danger-dark border-solid border-2 border-danger dark:border-danger-dark underline font-medium font-poppins min-w-[8.5rem] !flex',
    HIGH: '!bg-danger dark:!bg-danger-dark min-w-[8.5rem] font-poppins font-normal',
    MEDIUM: '!bg-warning dark:!bg-warning-dark min-w-[8.5rem] font-poppins font-normal',
    LOW: '!bg-success dark:!bg-success-dark min-w-[8.5rem] font-poppins font-normal',
};

export const getPriorityAndAlertType = ( dueDate: string ): {
    priority: 'OVERDUE' | 'HIGH' | 'MEDIUM' | 'LOW',
    alertType: 'danger' | 'warning' | 'success',
} =>
{
    if ( !dueDate )
    {
        return {
            priority: 'OVERDUE',
            alertType: 'danger',
        };
    }

    const nextDue = moment( dueDate );
    const now = moment();

    // if overdue
    if ( now > nextDue )
    {
        return {
            priority: 'OVERDUE',
            alertType: 'danger',
        };
    }

    // if within 1 month before next due
    if ( now > nextDue.clone().subtract( 1, 'month' ) )
    {
        return {
            priority: 'HIGH',
            alertType: 'danger',
        };
    }

    // if between 1 month and 3 months before next due
    if ( now > nextDue.clone().subtract( 3, 'month' ) )
    {
        return {
            priority: 'MEDIUM',
            alertType: 'warning',
        };
    }

    // if more than 3 months away from next due
    return {
        priority: 'LOW',
        alertType: 'success',
    };
};

export const getPriorityAndAlertPill = ( text: string | keyof typeof deadlinePriorityColours, className?: string ) => <DataFormat raw={ <>
    { text === 'OVERDUE' && <SVGIcon src={ warningIcon.src } className="!w-5 !h-5 text-danger dark:text-danger-dark inline-flex mr-1 -mb-1"/> }
    { text }
</> } type="pill" className={ deadlinePriorityColours[ text ] + ' ' + className } disableEllipsis/>;

export const getDataEntry = ( company: IORCompany, key: 'confirmation_statement' | 'accounts', index: number ) => ( {
    key: company.name + key + index,
    company: {
        uuid: company.uuid,
        name: company.name,
        colour: COMPANY_COLOURS[ index % COMPANY_COLOURS.length ],
        colourDark: COMPANY_COLOURS_DARK[ index % COMPANY_COLOURS_DARK.length ],
        type: getPriorityAndAlertType( company.ch_data.chProfile[ key ]?.next_due || '' ).alertType,
        tags: company.tags,
    },
    filing: formatWordsWithSeparator( key, '_' ),
    date: company.ch_data.chProfile[ key ]?.next_due || '',
    priority: getPriorityAndAlertType( company.ch_data.chProfile[ key ]?.next_due || '' ).priority,
} );

export const parseCompanies = ( companies: IORCompany[] = [] ) => companies
      .filter( company =>
            company.ch_data.chProfile.accounts?.next_due ||
            company.ch_data.chProfile.confirmation_statement?.next_due
      )
      .sort( ( a, b ) => compare( a.name, b.name, true ) )
      .map( ( company, index ) => [
          getDataEntry( company, 'confirmation_statement', index ),
          getDataEntry( company, 'accounts', index )
      ] )
      .flat()
      .sort( ( a, b ) => compare( a.date, b.date, true ) );