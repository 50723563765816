globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"ef694785955a9df9c5a44ca0c2a4ca9a642dece1"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import commonConfig from './sentry.common.config';

Sentry.init(Object.assign({}, commonConfig, {
    integrations: [Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false
    })/*, Sentry.feedbackIntegration({
        colorScheme: "system",
        isNameRequired: true,
        isEmailRequired: true,
    })*/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
}));
